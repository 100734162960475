<template>
    <div
      id="modal-Email"
      class="modal"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
     <load-spinner v-if="isLoadSpinner"/>
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Email History</h5>
          </div>
            <div class="modal-body" v-if="detailEmail.id">
                <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">ID :</label>
                      <label class="col-lg-7 col-form-label">{{detailEmail.id}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Created date:</label>
                      <label class="col-lg-7 col-form-label">{{detailEmail.createdDate}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Subjek :</label>
                      <label class="col-lg-7 col-form-label">{{detailEmail.subject}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Sender :</label>
                      <label class="col-lg-7 col-form-label">{{detailEmail.sender}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">CC :</label>
                      <label class="col-lg-7 col-form-label">{{detailEmail.cc | checkData}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">BCC :</label>
                      <label class="col-lg-7 col-form-label">{{detailEmail.bcc | checkData}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Recipient :</label>
                      <label class="col-lg-7 col-form-label">{{detailEmail.recipient | checkData}}</label>
                  </div>
                  <div class="form-group row mb-2">
                      <label class="col-lg-3 col-form-label font-weight-bold">Email Body :</label>
                      <div class="col-lg-9 col-form-label border border-solid overflow-auto" v-html="detailEmail.mimeMessage"></div>
                  </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-clean btn-bold btn-upper btn-font-md"
                @click="clearData()"
              >Close</button>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import { mapState, mapMutations, mapActions } from 'vuex';
  import LoadSpinner from "./../../_general/LoadSpinner";
  
  export default {
      components: {
      LoadSpinner
    },
    props: {
      idEmail: {
        type: Number,
        default: 0
      }
    },
    computed: {
      ...mapState('NotifikasiStore', {
         detailEmail: state => state.detailEmail,
        isLoadSpinner: state => state.isLoadSpinner
      }),
    },
    filters: {
        formatDate: function(value) {
            return moment(value).format("DD-MMM-YYYY HH:mm:ss");
        }
    },
    methods: {
      ...mapActions({
         getByIdEmail: 'NotifikasiStore/GET_HISTORY_DETAIL_EMAIL'
      }),
      ...mapMutations({
         changeShowModal: 'NotifikasiStore/SET_MODAL_EMAIL'
      }),
       clearData() {
          $("#modal-Email").modal('hide');
          this.changeShowModal(false)
      },
    },
    async mounted() {
      var vx = this;
      $("#modal-Email").modal();
      await this.getByIdEmail(this.idEmail)
  
    },
    filters: {
        checkData(data) {
            if(!data) return ''
            return data.replace('[', '').replace(']', '')
        }
    }
  }
  </script>
  